
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UpperHeader from '../components/layout/UpperHeader';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BelowFooter from '../components/layout/BelowFooter';
import CommonBanner from '../components/common/CommonBanner';

import BannerImg from '../assets/images/Banners/ProvidersBanner.png'
import MiddleCTA from '../components/common/MiddleCTA';
import ProvidersTwo from '../components/common/ProvidersTwo';
import MapSectionTwo from '../components/common/MapSectionTwo';
import Testimonials from '../components/common/Testimonials';
import TestimonialsServices from '../components/common/TestimonialsServices';


const Providers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
    <Helmet>
      <title>
      Providers | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
    <UpperHeader/>
    <Header/>
    <CommonBanner
    ClsName='container-fluid bg-common bg-providers d-block d-md-none'
    Title1="PROVIDERS"
    SubTitle1="Healthcare Professionals"
    Bg={BannerImg}
    />
    <ProvidersTwo/>
    <MapSectionTwo/>
    <TestimonialsServices/>
    <MiddleCTA/>
    <Footer/>
    <BelowFooter/>
    </>
  );
}

export default Providers;
