
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UpperHeader from '../components/layout/UpperHeader';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BelowFooter from '../components/layout/BelowFooter';
import JotformEmbed from 'react-jotform-embed';
import InquiryForm from '../components/contactus/InquiryForm';

const BookanAppointment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
    <Helmet>
      <title>
      Book Online | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
        <UpperHeader/>
    <Header/>
    <section id='website-responsive' className='container-fluid py-5 bg-ss-secondary'>
      <div className="fs-ss-40 fw-700 text-white text-center py-3 ">
      Book Appointment Online
      </div>
    </section>
    <div className="py-5" />
    <InquiryForm/>
    <div className="py-5" />
          <Footer/>
    <BelowFooter/>
    </>
  )
}
export default BookanAppointment;
