
import React from 'react';
import CommonButton from './CommonButton';
import { Link } from 'react-router-dom';

const ServicesCommonBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className='container-fluid d-none d-lg-block px-0 position-relative' >  
    <img src={props.Bg} className='w-100' alt={props.Alt}/>
    <div className='banner-text-services font-ss-pri d-none d-md-block'>
      <div className='fs-ss-46 text-black fw-ss-700 text-uppercase lh-57'>
      {props.Title1} <br/> {props.Title2}
      </div>
      <div className='text-black fs-ss-16 fw-ss-500 py-3'>
      {props.SubTitle}
      </div>
      <a href="tel:1234567890" className='text-decoration-none' target='_blank'>
      <CommonButton
      ClsName='btn btn-light bg-ss-primary btn-ss-primary-secondary rounded-3 border-0 fs-ss-17 px-4 py-2 fw-ss-500 mb-3'
      Title="123-456-7890"/>
      </a>
      <br/>
      <Link className='text-decoration-none' to="/book-an-appointment">
      <CommonButton
      ClsName='btn btn-light btn-ss-secondary-primary rounded-3 border-0 fs-ss-17 px-4 py-2 fw-ss-500'
      Title="&nbsp;&nbsp;Book Online &nbsp;&nbsp;"/>
      </Link>
    </div>
    </section>
    
        {/* Mobile */}

    <section id='website-responsive' className="container-fluid bg-mb d-block d-lg-none">  
    <div className='text-center pt-5 font-ss-pri'>
      <div className='fs-ss-52 text-black fw-ss-700 pb-2'>
      {props.Title1} <br className='d-block d-md-none'/> {props.Title2}
      </div>
      <div className='text-black fs-ss-14 fw-ss-500 pb-3'>
      {props.SubTitle}
      </div>
      <a href="tel:1234567890" className='text-decoration-none' target='_blank'>
      <CommonButton
      ClsName='btn btn-light bg-ss-primary btn-ss-primary-secondary rounded-3 border-0  fs-ss-14 px-4 py-2 fw-ss-500 mb-2 '
      Title="123-456-7890"/>
      </a><br/>
      <Link className='text-decoration-none' to="/book-an-appointment">
      <CommonButton
      ClsName='btn btn-light btn-ss-secondary-primary rounded-3 border-0 fs-ss-14 px-4 py-2 fw-ss-500'
      Title="&nbsp;&nbsp;Book Online&nbsp; &nbsp;"/>
      </Link>
    </div>
    </section>
    </>


  );
}

export default ServicesCommonBanner;
