
import React from 'react';
import CommonButton from './CommonButton';

const BelowBanner2 = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly gy-4 align-items-center">
        <div className="col-12 col-lg-6 ">
          <div className='fs-ss-32 text-black font-ss-pri fw-ss-600 py-2'>
            {props.Title1} <span className='text-ss-primary'> {props.Title2} </span>
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?
          </div>
            </div>
            <div className="col-9 col-lg-5 text-center">
          <img className='w-85' src={props.Img1} alt={props.Alt} />
        </div>
      </div>
    </section>
    </>


  );
}

export default BelowBanner2;
