
import React from 'react';
import CommonButton from './CommonButton';
import { Link } from 'react-router-dom';


const CommonBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className='container-fluid d-none d-md-block px-0 position-relative' >  
    <img src={props.Bg} className='w-100' alt={props.Alt}/>
    <div className='banner-text font-ss-pri d-none d-md-block'>
      <div className='fs-ss-48 text-white fw-ss-700 text-uppercase lh-57'>
      {props.Title1} <br className='d-none d-lg-block' /> {props.Title2}
      </div>
      <div className='text-black fs-ss-20 fw-ss-500 pt-md-2 pb-md-4 py-3'>
      {props.SubTitle1} {props.SubTitle2}
      </div>
      <a href="tel:1234567890" className='text-decoration-none' target='_blank'>
      <CommonButton
      ClsName='btn btn-light bg-ss-primary hover-white rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500 mb-3'
      Title="123-456-7890"/>
      </a>
      <br/>
      <Link className='text-decoration-none' to="/book-an-appointment">
      <CommonButton
      ClsName='btn btn-light hover-pri rounded-3 border-0 fs-ss-17 px-4 py-2 fw-ss-500'
      Title="&nbsp;&nbsp;Book Online &nbsp;&nbsp;"/>
      </Link>
    </div>
    </section>
    
        {/* Mobile */}

    <section id='website-responsive' className={props.ClsName} >  
    <div className='banner-text-mb font-ss-pri'>
      <div className='fs-ss-26 text-white fw-ss-700'>
      {props.Title1} <br/> {props.Title2}
      </div>
      <div className='text-black fs-ss-14 fw-ss-500 pb-2'>
      {props.SubTitle1} <br className='d-block d-sm-none'/>  {props.SubTitle2}
      </div>
      <a href="tel:1234567890" className='text-decoration-none' target='_blank'>
      <CommonButton
      ClsName='btn btn-light bg-ss-primary hover-white rounded-3 border-0  fs-ss-12 px-4 py-2 fw-ss-500 mb-1'
      Title="123-456-7890"/>
      </a>
      <br/>
      <Link className='text-decoration-none' to="/book-an-appointment">
      <CommonButton
      ClsName='btn btn-light hover-pri rounded-3 border-0 fs-ss-12 px-4 py-2 fw-ss-500'
      Title="&nbsp;&nbsp; Book Online &nbsp;"/>
      </Link>
    </div>
    </section>
    </>


  );
}

export default CommonBanner;
