
import React from 'react';
import {Link} from 'react-router-dom'

const BelowFooter = () => {
  return (
    <>
    <section id='website-responsive'  className='container-fluid bg-light py-2 text-center text-xl-start border-top border-black border-1'>
      <div className="px-xl-5 px-2 fs-ss-16">
        <div className="row gy-2">
        <div className="col-12 col-lg-4 d-block d-lg-none">
            <span className='border-end border-1 border-dark me-3 pe-3 '> <Link className='text-decoration-none hover-pri-black' to="/privacy-policy"> Privacy Policy </Link> </span>
            <Link className='text-decoration-none hover-pri-black' to="/terms-and-conditions"> <span>Terms & Conditions</span> </Link> 
          </div>
          <div className="col-12 col-lg-8" style={{fontWeight:"200"}}>
          Copyright © 2024 Bright Kids Pediatrics Clinic | Powered by <a className='text-decoration-none text-ss-primary' target='_blank' href="https://simplesolutionz.org/"><span>Simple Solutionz</span></a>
          </div>
          <div className="col-12 col-lg-4 d-none d-lg-block">
            <span className='border-end border-1 border-dark me-2 pe-2 '> <Link className='text-decoration-none hover-pri-black' to="/privacy-policy"> Privacy Policy </Link> </span>
            <Link className='text-decoration-none hover-pri-black' to="/terms-and-conditions"> <span>Terms & Conditions</span> </Link> 
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default BelowFooter;
