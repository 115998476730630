import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'
import CommonButton from "../common/CommonButton";

const InquiryForm = () =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('gmail_service','brightkids',e.target,'3cIb1sgzlfNqwBYS8').then(res=>{
    console.log(res)
    alert("Your form has been submitted")
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
    <section id='website-responsive' className="container-fluid px-lg-5 mt-lg-4">
      <div className="text-black font-poppins fs-ss-24 fw-ss-600 text-center px-3">
                Send A Message To Bright Kids Pediatrics Clinic
                </div>
                <p className="fs-ss-16 text-center pt-3 px-4">
                If you have any questions, concerns, or message regarding Bright Kids Pediatrics Clinic, please fill out the short contact form below.
                </p>
        <div className="row justify-content-center align-items-center mx-lg-2 gy-5 pb-5">
            <div className="col-11 col-lg-6 text-center ">
                <div className="">
                <div className="text-center col-12 px-lg-4">
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="Get" action="#">
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="first_name">First Name</label>
        <input type="text" className="form-control bg-light" id="first_name" name="first_name" placeholder="First Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="last_name">Last Name</label>
        <input type="text" className="form-control bg-light" id="last_name" name="last_name" placeholder="Last Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="email">Email Address</label>
        <input type="email" className="form-control bg-light" id="email" name="email" placeholder="Email Address*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="phone_number">Phone Number</label>
        <input type="number" className="form-control bg-light" id="phone_number" name="phone_number" placeholder="Phone Number*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="message">Message</label>
        <textarea className="col-pri form-control bg-light" id="message" name="message" rows="5" placeholder="Message*" required></textarea>
      </div>
      <div className="pt-3 text-center">
      <ReCAPTCHA
    sitekey="6Lcsx1AnAAAAAKLWvgnuolzWsuIeqnNOQQgucT88" 
    // sitekey="6LfZjx4nAAAAAMnyR_l8ISMXnPJtao1SC1C2joyh"
    onChange={onChange}/>
        <CommonButton
      ClsName='btn btn-light btn-ss-secondary-primary rounded-3 border-0  fs-ss-17 px-4 py-2 mt-2 fw-ss-500 mb-3'
      Title="Send"/>
      </div>
    </form>
    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}

export default InquiryForm;