
import React from 'react';
import CommonButton from './CommonButton';
import Provider1 from '../../assets/images/Providers/Provider1.png'
import Provider2 from '../../assets/images/Providers/Provider2.png'
import Provider3 from '../../assets/images/Providers/Provider3.png'
import {Link} from 'react-router-dom'
import ModelProvider1 from './ModelProvider1';
import ModelProvider2 from './ModelProvider2';
import ModelProvider3 from './ModelProvider3';

const Providers = () => {
  return (
    <>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className='text-center pb-md-5 pb-4'>
      <div className='fs-ss-22 text-ss-primary font-ss-pri fw-ss-400'>
        Our Providers
          </div>
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-700 pt-2'>
          Meet The Hands That Care
          </div>
      </div>
      <div className="row justify-content-center gy-4">
        <div className="text-center col-8 col-md-3"> <img className='w-65' src={Provider1} alt="Provider 1" />
        <div className='fs-ss-22 font-ss-pri fw-ss-400 pt-3' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal">
        <div> Talmage Bethany </div>
        <div>CRNP</div>
        </div>
        </div>
        <div className="text-center col-8 col-md-3"> <img className='w-65' src={Provider3} alt="Provider 2" />
        <div className='fs-ss-22 font-ss-pri fw-ss-400 pt-3' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal2">
        <div> Talmage Bethany </div>
        <div>NP</div>
        </div>
        </div>
        <div className="text-center col-8 col-md-3"> <img className='w-65' src={Provider2} alt="Provider 3" />
        <div className='fs-ss-22 font-ss-pri fw-ss-400 pt-3' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal3">
        <div> Talmage Bethany </div>
        <div>MD</div>
        </div>
        </div>
      </div>

      <div className="col-12 text-center pt-md-5 pt-4">
        <Link to="/providers">
      <button className='btn btn-light bg-ss-primary btn-ss-primary-secondary text-white rounded-3 border-0 fs-ss-17 px-5 py-2 fw-ss-500 mb-3'>
          View All       
        </button>
        </Link>
      </div>
    </section>

    <ModelProvider1/>
    <ModelProvider2/>
    <ModelProvider3/>
    </>


  );
}

export default Providers;
