
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UpperHeader from '../components/layout/UpperHeader';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BelowFooter from '../components/layout/BelowFooter';
import CommonBanner from '../components/common/CommonBanner';

import BannerImg from '../assets/images/Banners/HomeBanner.webp'
import MainServices from '../components/home/MainServices';
import BelowBanner from '../components/common/BelowBanner';

import Img1 from '../assets/images/Home/Img1.webp'
import HeadingPara from '../components/common/HeadingPara';

import Client1 from '../assets/images/Clients/Client1.webp'
import Client2 from '../assets/images/Clients/Client2.webp'
import Client3 from '../assets/images/Clients/Client3.webp'
import Client4 from '../assets/images/Clients/Client4.webp'
import MapSection from '../components/common/MapSection';
import MiddleCTA from '../components/common/MiddleCTA';
import Providers from '../components/common/Providers';
import Testimonials from '../components/common/Testimonials';


const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
    <Helmet>
      <title>
     Home | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
    <UpperHeader/>
    <Header/>
    <CommonBanner
    ClsName='container-fluid bg-common bg-home d-block d-md-none'
    Title1={<>Bright Kids</>}
    Title2={<>Pediatrics<br className="d-none d-md-block"/> Clinic</>}
    SubTitle1="Pediatrics Clinic in"
    SubTitle2="USA"
    Bg={BannerImg}
    />
    <BelowBanner
    Img1={Img1}
    SubTitle="More About Us"
    Title="SPECIAL CARE FOR YOUR CHILDREN"
    Para1_1="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?"
    br={<div className='py-2'/>}
    Para1_2="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae."
    />
    <section className="px-lg-5">
    <HeadingPara
    Title1_1="At Bright Kids Pediatrics Clinic,"
    Title1_2="Preventive health is a central focus!"
    Para1_1="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?"
    br={<div className='py-2'/>}
    Para1_2="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?"
    />
    </section>
    <MainServices/>
    <section className="px-lg-5">
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly align-items-center">
        <div className="col-12">
          <div className='fs-ss-30 font-ss-pri fw-ss-700 text-black py-2'>
          We accept Medicaid, Blue Cross, Tricare, <br className='d-none d-md-block' /> United, and other Insurance Plans
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          <strong> At Bright Kids Pediatrics Clinic, </strong> we pride ourselves on being accessible and compassionate, so we choose to accept most commercial insurance plans for your ease, including Medicaid, Blue Cross, Tricare, etc. In recent times the number of healthcare plan options has increased substantially. We endeavor to ensure that we are in contact with your insurance provider, working to make sure that the available services provided are covered; however, we cannot guarantee that your insurance company will cover all the services provided to your child at our clinic. Patients and their representative parties are responsible for any cost not covered by their healthcare plan.
          </div>
            </div>
      </div>
    </section>
    </section>

    <section className="container-fluid px-3 px-md-5 pt-4 pt-md-5">
      <div className="row justify-content-evenly gy-4  ">
        <div className="col-5 col-md-2"> <img className='w-100' src={Client1} alt="Client 1" /> </div>
        <div className="col-5 col-md-2"> <img className='w-100' src={Client2} alt="Client 2" /> </div>
        <div className="col-5 col-md-2"> <img className='w-100' src={Client3} alt="Client 3" /> </div>
        <div className="col-5 col-md-2"> <img className='w-100' src={Client4} alt="Client 4" /> </div>
      </div>
    </section>
    <Testimonials/>
    <Providers/>
    <MiddleCTA/>
    <MapSection/>
    <Footer/>
    <BelowFooter/>
    </>
  );
}

export default HomePage;
