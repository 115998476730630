
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UpperHeader from '../components/layout/UpperHeader';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BelowFooter from '../components/layout/BelowFooter';
import CommonBanner from '../components/common/CommonBanner';

import BannerImg from '../assets/images/Banners/ContactUsBanner.png'
import ContactSection from '../components/contactus/ContactSection';
import Mobile from '../assets/images/ContactUs/Mobile.webp';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
    <Helmet>
      <title>
      Contact Us | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
    <UpperHeader/>
    <Header/>
    <CommonBanner
    ClsName='container-fluid bg-common bg-contactus d-block d-md-none'
    Title1="CONTACT US"
    Bg={BannerImg}
    />
    <ContactSection
    Mobile={Mobile}
    />
    <Footer/>
    <BelowFooter/>
    </>
  );
}

export default ContactUs;
