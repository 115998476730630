
import React from 'react';
import { GeoAltFill, TelephoneFill } from 'react-bootstrap-icons';

const UpperHeader = () => {
  return (
    <>
    <section id='website-responsive' className='container-fluid bg-ss-primary py-2 text-center text-lg-start'>
      <div className='px-2 px-md-5'>
        <div className='row'>
          <div className='col-12 col-md-4 text-white fw-ss-500 d-none d-lg-block'>
            Lorem ipsum dolor sit
          </div>
          <div className='col-12 col-md-11 col-lg-8 text-md-end'>
            <a href='tel:1234567890' target="_blank" className='text-decoration-none'> <TelephoneFill className='text-white'/> <span className='ps-2'/> <span className='text-white fw-ss-500'> 123-456-7890 </span> </a> <span className='px-3'/> <br className='d-block d-md-none'/>
            <a href='https://maps.app.goo.gl/PkNu74KqRbPHRTfu9' className='text-decoration-none' target="_blank"> <GeoAltFill className='text-white'/> <span className='ps-2'/>  <span className='text-white fw-ss-500'> Washington DC, USA </span> </a>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default UpperHeader;
