
import React, {useEffect} from 'react';
import UpperHeader from '../../components/layout/UpperHeader';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import BelowFooter from '../../components/layout/BelowFooter';

import BannerImg from '../../assets/images/Banners/PrimaryCareServiceBanner.jpg'
import ServicesCommonBanner from '../../components/common/ServicesCommonBanner';
import NewbornCareServiceImg from '../../assets/images/Services/NewbornCareServiceImg.webp'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ServicesMiddleCTA from '../../components/common/ServicesMiddleCTA';
import TestimonialsServices from '../../components/common/TestimonialsServices';




const PrimaryCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
        <>
    <Helmet>
      <title>
      Primary Care | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
    <UpperHeader/>
    <Header/>
    <ServicesCommonBanner
        ClsName='container-fluid bg-mb d-block d-md-none'
        Title1="Primary Care"
        Title2="SPECIALIST"
        SubTitle="Get the right care for your child"
        Bg={BannerImg}
    />
        <section className="px-lg-5">
        <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly align-items-center">
        <div className="col-12">
          <div className='fs-ss-35 font-ss-pri fw-ss-700 text-black py-2'>
          Primary Care Services Offered in USA
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?
          </div>
            </div>
      </div>
    </section>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly align-items-center gy-4">
                <div className="col-12 col-md-4 d-none d-lg-block">
          <img className='w-100' src={NewbornCareServiceImg} alt="Primary Care Service Img" />
        </div>
                <div className="col-12 col-lg-7">
          <div className='fs-ss-32 font-ss-pri text-ss-primary fw-ss-600 pb-2'>
          Why are Primary Care visits so important?
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?
          </div>
            </div>
            <div className="col-7 d-block d-lg-none">
          <img className='w-100' src={NewbornCareServiceImg} alt="Primary Care Service Img" />
        </div>
      </div>
    </section>
    </section>
    <ServicesMiddleCTA/>
    <section className="px-lg-5">
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly align-items-center">
        <div className="col-12">
          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          What happens during Primary Care appointments?
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?
          </div>
            </div>
      </div>
    </section>

    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-3">
      <div className="row justify-content-evenly align-items-center">
        <div className="col-12">
          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          What topics can I discuss during Primary Care visits?
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?
          </div>
            </div>
      </div>
    </section>
    </section>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 py-md-5 py-4 mt-4 mt-md-5" style={{backgroundColor:"#fff4d4"}}>
      <div className="row px-md-5 ">
        <div className="col-12 text-center">
          <div className='fs-ss-22 text-black fw-ss-600'>
          Booking a Primary Care appointment at Bright Kids Pediatrics Clinic takes just a few moments when you use the online scheduling page. You’re also welcome to call the office to check appointment availability.
          </div>
          <div className='btn-group pt-md-5 pt-3'>
          <button className="btn btn-light bg-ss-primary btn-ss-primary-secondary rounded-3 border-0 fs-ss-17 px-4 py-2 fw-ss-500 d-block me-3">
          <a href="tel:1234567890" className='text-decoration-none text-white' target='_blank'>
          123-456-7890
          </a>
          </button>
          <button className="d-inline btn btn-light bg-ss-primary btn-ss-primary-secondary rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500 d-block ms-3">
          <Link className='text-decoration-none text-white' to="/book-an-appointment">
          &nbsp; &nbsp; Book Online &nbsp;&nbsp;
          </Link>
          </button>
          </div>
            </div>
      </div>
    </section>

<TestimonialsServices/>
    <Footer/>
    <BelowFooter/>
    </>
  );
}

export default PrimaryCare;
