
import React from 'react';
import CommonButton from './CommonButton';

const MapSection = () => {
  return (
    <>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 py-md-5 py-4">
      <div className="row justify-content-evenly gy-4 align-items-center">
        <div className="col-12 col-sm-7 col-md-5 ">
          <div className='fs-ss-22 text-ss-primary font-ss-pri fw-ss-400'>
          Our Location
          </div>
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-700 py-2'>
          Partnership Based On Trust
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30 pb-4'>
          We help you in your goal to ensure that your child’s health never falls below the peak condition. So, your expectations may be high, but we always work hard to reach further. If you seek attentive and accessible care for your child, then our pediatrics clinic is the place for you!
          </div>
          <a className='text-decoration-none' target='_blank' href="https://maps.app.goo.gl/PkNu74KqRbPHRTfu9">
          <CommonButton
      ClsName='btn btn-light bg-ss-primary text-white btn-ss-primary-secondary rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500 d-block mb-3'
      Title="View on Google Map"/>
      </a>
            </div>
            <div className="col-12 col-sm-4 col-md-6 ">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99370.29950629387!2d-77.01457599999999!3d38.8937545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20DC%2C%20USA!5e0!3m2!1sen!2s!4v1717127539069!5m2!1sen!2s" width="800" height="350" className='rounded-0 w-100' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
        </div>
      </div>
    </section>
    </>


  );
}

export default MapSection;
