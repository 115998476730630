
import React from 'react';
import CommonButton from './CommonButton';

const HeadingPara = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly align-items-center">
        <div className="col-12">
          <div className='fs-ss-30 font-ss-pri fw-ss-700 text-ss-primary py-2'>
            {props.Title1_1} <span className='text-black'> {props.Title1_2} </span>
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          {props.Para1_1}
          {props.br}
          {props.Para1_2}
          </div>
            </div>
      </div>
    </section>
    </>


  );
}

export default HeadingPara;
