
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UpperHeader from '../components/layout/UpperHeader';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BelowFooter from '../components/layout/BelowFooter';
import CommonBanner from '../components/common/CommonBanner';

import BannerImg from '../assets/images/Banners/AboutUsBanner.webp'
import Img1 from '../assets/images/AboutUs/Img1.webp'
import Step1 from '../assets/images/AboutUs/01.png'
import Step2 from '../assets/images/AboutUs/02.png'
import Step3 from '../assets/images/AboutUs/03.png'
import Step4 from '../assets/images/AboutUs/04.png'
import Step5 from '../assets/images/AboutUs/05.png'
import Left from '../assets/images/AboutUs/Left.png'
import Right from '../assets/images/AboutUs/Right.png'
import ChildImg from '../assets/images/AboutUs/ChildImg.webp'
import ChildImg2 from '../assets/images/AboutUs/ChildImg2.webp'
import BelowBanner from '../components/common/BelowBanner';
import BelowBanner2 from '../components/common/BelowBanner2';
import MapSection from '../components/common/MapSection';
import MiddleCTA from '../components/common/MiddleCTA';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
    <Helmet>
      <title>
      About Us | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
    <UpperHeader/>
    <Header/>
    <CommonBanner
    ClsName='container-fluid bg-common bg-aboutus d-block d-md-none'
    Title1="ABOUT US"
    SubTitle1="Healthcare Professionals"
    Bg={BannerImg}
    />
    <BelowBanner
    Img1={Img1}
    SubTitle="More About"
    Title="Bright Kids Pediatrics Clinic"
    Para1_1="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?"
    br={<div className='py-2'/>}
    Para1_2="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae."
    />
        <BelowBanner2
    Img1={ChildImg2}
    Title1="Bright Kids Pediatrics Clinic"
    Title2="is honored to offer"
    />
    <MiddleCTA/>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
    <div className='fs-ss-30 font-ss-pri fw-ss-700 text-black pb-3 pb-md-5 text-center'>
    At Bright Kids Pediatrics Clinic, your pediatrician <br/> will be in charge of the following:
          </div>
      <div className="row justify-content-evenly gy-5 align-items-center">
      <div className="col-9 col-lg-5 ">
          <img className='w-100' src={ChildImg} alt="Child" />
        </div>
        <div className="col-12 col-lg-5 fw-600">
          <div className='d-flex pb-4'>
            <div className=''>
            <img className='pe-lg-0 pe-4' src={Step1} alt="Step1" />
            </div>
            <div className='px-lg-3 pe-4'> Providing you with health <br className="d-none d-lg-block" /> education and guidance.</div>
            <div>
            <img className='d-none d-lg-inline' src={Right} alt="Right Arrow" />
            </div>
          </div>
          <div className='d-flex pb-4'>
          <div>
            <img className='d-none d-lg-inline' src={Left} alt="Left Arrow" />
            </div>
            <div className=''>
            <img className='px-lg-3 pe-4' src={Step2} alt="Step2" />
            </div>
            <div className=''> Identifying and treating your child's <br className="d-none d-lg-block" /> illnesses, injuries, conditions, <br className="d-none d-lg-block" /> diseases, and infections.</div>
          </div>
          <div className='d-flex pb-4'>
            <div className=''>
            <img className='pe-lg-0 pe-4' src={Step3} alt="Step3" />
            </div>
            <div className='px-lg-3 pe-4'> Ensuring that your child is <br className="d-none d-lg-block" /> physically, mentally, and socially <br className="d-none d-lg-block" /> developing at a regular rate. </div>
            <div>
            <img className='d-none d-lg-inline' src={Right} alt="Right Arrow" />
            </div>
          </div>
          <div className='d-flex pb-4'>
          <div>
            <img className='d-none d-lg-inline' src={Left} alt="Left Arrow" />
            </div>
            <div className=''>
            <img className='px-lg-3 pe-4' src={Step4} alt="Step4" />
            </div>
            <div className=''> Referring to other specialists if your <br className="d-none d-lg-block" /> child has a complex condition. </div>
          </div>
          <div className='d-flex pb-4'>
            <div className=''>
            <img className='pe-lg-0 pe-4' src={Step5} alt="Step5" />
            </div>
            <div className='px-lg-3 pe-4'> Recommending vaccinations </div>
          </div>
            </div>
      </div>
    </section>
    <MapSection/>
    <Footer/>
    <BelowFooter/>
    </>
  );
}

export default AboutUs;
