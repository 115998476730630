
import React from 'react';
import { Link } from 'react-router-dom';
import AnnouncementImg from '../../assets/images/Providers/Announcement.webp'

const ServicesMiddleCTA = (props) => {
  return (
    <>
        <section id='website-responsive' className="container-fluid px-md-5 px-3 py-5 mt-md-5 mt-4" style={{backgroundColor:"#fcd3db"}}>
      <div className="row justify-content-evenly gy-4 align-items-center">
        <div className="col-12 col-lg-6 ">
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-700 pb-2'>
          Partnership Based On Trust
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30 pb-4'>
          We help you in your goal to ensure that your child’s health never falls below the peak condition. So, your expectations may be high, but we always work hard to reach further. If you seek attentive and accessible care for your child, then our pediatrics clinic is the place for you!
          </div>
          <div id='middle-cta' className='btn-group pt-3'>
          <a href="tel:1234567890" className='text-decoration-none' target='_blank'>
          <button className="btn btn-light bg-ss-primary btn-ss-primary-secondary rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500 d-block me-3">
          123-456-7890
          </button>
          </a>
          <Link className='text-decoration-none' to="/book-an-appointment">
          <button className="d-inline btn btn-light bg-ss-primary btn-ss-secondary-primary rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500 d-block ms-3">
          &nbsp; &nbsp; Book Online &nbsp;&nbsp;
          </button>
          </Link>
          </div>
            </div>
            <div className="col-9 col-sm-4 col-md-5 text-center ">
            <img className='w-50 d-none d-lg-inline'  src={AnnouncementImg} alt="AnnouncementImg" />
            <img className='w-75 d-inline d-lg-none'  src={AnnouncementImg} alt="AnnouncementImg" />
        </div>
      </div>
    </section>
    </>


  );
}

export default ServicesMiddleCTA;
