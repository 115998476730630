
import React from 'react';
import ServiceLayout from '../home/ServiceLayout'
import Icon1 from '../../assets/images/Home/Icon1.png'
import Icon2 from '../../assets/images/Home/Icon2.png'
import Icon3 from '../../assets/images/Home/Icon3.png'
import Icon4 from '../../assets/images/Home/Icon4.png'
import Icon5 from '../../assets/images/Home/Icon5.png'
import Icon6 from '../../assets/images/Home/Icon6.png'
import Icon7 from '../../assets/images/Home/Icon7.png'
import Icon8 from '../../assets/images/Home/Icon8.png'

const MainServices = (props) => {
  return (
    <>
    <section id='website-responsive'  className='container-ss pt-4 pt-md-5'>
      <div className="text-center pb-5">
      <div className='fs-ss-22 text-ss-primary font-ss-pri fw-ss-600'>
      Services We Provide
          </div>
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-800 pt-2'>
          Providing Professional Care <br className='d-none d-md-block' /> For Your Loving Kids 
          </div>
      </div>
      <div className="row justify-content-evenly gy-4">
                <ServiceLayout To="/newborn-care"
        Icon1={Icon1}
        Icon_Width="w-icon"
        BgColor={{backgroundColor:"#c5effd"}}
        Title="Newborn Care"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-secondary"
        />
                <ServiceLayout To="/well-child-visits"
        Icon1={Icon2}
        Icon_Width="w-icon-services"
        BgColor={{backgroundColor:"#fcd3db"}}
        Title="Well Child Visits"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-primary"
        />
                <ServiceLayout To="/sick-visits"
        Icon1={Icon3}
        Icon_Width="w-icon-services"
        BgColor={{backgroundColor:"#c5effd"}}
        Title="Sick Visits"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-secondary"
        />
                <ServiceLayout To="/mental-health"
        Icon1={Icon4}
        Icon_Width="w-icon-services"
        BgColor={{backgroundColor:"#fff4d4"}}
        Title="Mental Health"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-yellow"
        />
      </div>
    </section>

    <section className='container-ss pt-4'>
      <div className="row justify-content-evenly gy-4">
                <ServiceLayout To="/lab-tests"
        Icon1={Icon5}
        Icon_Width="w-icon-services"
        BgColor={{backgroundColor:"#c5effd"}}
        Title="Lab Tests"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-secondary"
        />
                <ServiceLayout To="/covid-19"
        Icon1={Icon6}
        Icon_Width="w-icon-services"
        BgColor={{backgroundColor:"#fff4d4"}}
        Title="Covid-19 Test"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-yellow"
        />
                <ServiceLayout To="/vaccines"
        Icon1={Icon7}
        Icon_Width="w-icon-services ms-4"
        BgColor={{backgroundColor:"#c5effd"}}
        Title="Vaccines"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-secondary"
        />
                <ServiceLayout To="/primary-care"
        Icon1={Icon8}
        Icon_Width="w-icon-services"
        BgColor={{backgroundColor:"#fcd3db"}}
        Title="Primary Care"
        ClsName="pt-4 font-ss-pri fs-ss-22 fw-ss-700 text-ss-primary"
        />
      </div>
    </section>
    </>
  );
}

export default MainServices;
