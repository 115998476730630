
import React from 'react';
import Provider1 from '../../assets/images/AboutUs/Provider4.webp'


const ModelProvider1 = () => {
  return (
    <>
<div class="modal fade modal-lg" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div className="row justify-content-evenly py-3 gy-4">
          <div className="col-8 col-lg-4">
            <img src={Provider1}alt="" className="w-100" />
          </div>
          <div className="col-11 col-lg-7">
            <div className="fw-600 fs-ss-22">
            Savannah Savannah
            </div>
            <div className='fs-ss-20 pt-3'>
            CRNP
            </div>
            <div className='fs-ss-16 pt-3'>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae. Possimus velit quaerat quasi, in ad natus reiciendis tempora cumque assumenda laboriosam, quidem fugiat. Nesciunt quibusdam natus pariatur quasi voluptates beatae dicta dolores deserunt illo?
            </div>
            <div className='fs-ss-18-providers pt-3'>
              <span className='fw-600'> Phone: </span> <a className='text-decoration-none text-black' href="tel:12334567890"> 123-345-67890 </a>
            </div>
            <div className='fs-ss-18-providers pt-3'>
              <span className='fw-600'> Email: </span> <a className='text-decoration-none text-black' href="mailto:brightkids@gmail.com"> brightkids@gmail.com </a>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </>

  );
}

export default ModelProvider1;
