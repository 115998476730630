
import React from 'react';
import CommonButton from './CommonButton';
import Provider1 from '../../assets/images/AboutUs/Provider1.webp'
import Provider2 from '../../assets/images/AboutUs/Provider2.webp'
import Provider3 from '../../assets/images/AboutUs/Provider3.webp'
import Provider4 from '../../assets/images/AboutUs/Provider4.webp'
import Provider5 from '../../assets/images/AboutUs/Provider5.webp'
import ModelProvider1 from './ModelProvider1';
import ModelProvider2 from './ModelProvider2';
import ModelProvider3 from './ModelProvider3';
import ModelProvider4 from './ModelProvider4';
import ModelProvider5 from './ModelProvider5';

const ProvidersTwo = () => {
  return (
    <>
    <section id='website-responsive' className="container-ss px-md-5 px-3 pt-md-5 pt-4">
      <div className='text-center pb-md-5 pb-4'>
      <div className='fs-ss-22 text-ss-primary font-ss-pri fw-ss-400'>
        Our Providers
          </div>
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-700 pt-2'>
          Meet The Hands That Care
          </div>
      </div>
      <div className="row justify-content-evenly gy-4">
        <div className="text-center col-8 col-24 bg-ss-primary px-0 rounded-5"> <img className='w-100' src={Provider1} alt="Provider 1" />
        
      <div className='fs-ss-18 font-ss-pri fw-ss-400 text-white pt-3 pb-2' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal">
        <div>Savannah Savannah</div>
        <div>CRNP</div>
        </div>
        </div>
        <div className="text-center col-8 col-24 bg-ss-primary px-0 rounded-5"> <img className='w-100' src={Provider2} alt="Provider 2" />
                      <div className='fs-ss-18 font-ss-pri fw-ss-400 text-white pt-3 pb-2' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal2">
        <div>Savannah Savannah</div>
        <div>NP</div>
        </div>
        </div>
        <div className="text-center col-8 col-24 bg-ss-primary px-0 rounded-5"> <img className='w-100' src={Provider3} alt="Provider 3" />
                      <div className='fs-ss-18 font-ss-pri fw-ss-400 text-white pt-3 pb-2' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal3">
        <div>Savannah Savannah</div>
        <div>MD</div>
        </div>
        </div>
        <div className="text-center col-8 col-24 bg-ss-primary px-0 rounded-5"> <img className='w-100' src={Provider4} alt="Provider 1" />
                      <div className='fs-ss-18 font-ss-pri fw-ss-400 text-white pt-3 pb-2' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal4">
        <div>Savannah Savannah</div>
        <div>MSN, PMHNP-BC</div>
        </div>
        </div>
        <div className="text-center col-8 col-24 bg-ss-primary px-0 rounded-5"> <img className='w-100' src={Provider5} alt="Provider 2" />
                      <div className='fs-ss-18 font-ss-pri fw-ss-400 text-white pt-3 pb-2' type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal5">
        <div>Savannah Savannah</div>
        <div>CRNP</div>
        </div>
        </div>
        <div className="col-8 col-24 d-none d-lg-block"></div>
        <div className="col-8 col-24 d-none d-lg-block"></div>
        <div className="col-8 col-24 d-none d-lg-block"></div>
        </div>
    </section>


    {/* Modal */}
    <ModelProvider1/>
    <ModelProvider2/>
    <ModelProvider3/>
    <ModelProvider4/>
    <ModelProvider5/>

    </>


  );
}

export default ProvidersTwo;
