
import React from 'react';
import CommonButton from './CommonButton';
import {Link} from 'react-router-dom'

const MiddleCTA = () => {
  return (
    <>
    <section id='website-responsive' className="text-center container-fluid px-md-5 px-3 py-md-5 py-4 mt-3 mt-md-4" style={{backgroundColor:"#fff4d4"}}>
      <div className="row">
        <div className="col-12 text-center">
          <div className='fs-ss-30 text-ss-primary font-ss-pri fw-ss-600'>
          Make The Right Choice For Your
          </div>
          <div className='fs-ss-48 text-black font-ss-pri fw-ss-800 py-2'>
          CHILD'S HEALTHCARE
          </div>

          <div className='pt-md-5 pt-3'>
            <a className="text-decoration-none" href="tel:1234567890" target='_blank'>
          <button className="btn btn-light bg-ss-primary text-white btn-ss-white-black shadow rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500">
          123-456-7890
          </button>
          </a>
          <span className="px-md-4" />
          <Link className="text-decoration-none d-block d-md-inline" to="/book-an-appointment">
          <button className="mt-3 mt-md-0 btn btn-light text-white bg-ss-primary btn-ss-white-black shadow rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500">
          &nbsp;&nbsp;Book Online&nbsp;&nbsp;
          </button>
          </Link>
          </div>
            </div>
            </div>

            {/* <div className="row">
              <div className="col-8">
                <button>
                  205338-7866
                </button>
              </div>
              <div className="col-8">
                <button>
                Book Online
                </button>
              </div>
            </div> */}

            <div className="row">

            </div>
     

      
    </section>
    </>


  );
}

export default MiddleCTA;
