
import React from 'react';

const CommonButton = (props) => {
  return (
    <>
    <button className={props.ClsName} style={props.Styl}>
        {props.Title}
    </button>
    </>
  );
}

export default CommonButton;
