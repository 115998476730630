
import React from 'react';
import {Link} from 'react-router-dom'

const ServiceLayout = (props) => {
  return (
    <>
    <div id='website-responsive' className='col-10 col-lg-3 col-md-5 text-center'>
      <div className="py-5 px-1 rounded-5 shadow-sevices-layout" style={props.BgColor}>
      <img className={props.Icon_Width} src={props.Icon1} alt={props.Alt} />
      <div className={props.ClsName}> {props.Title}  </div>
      <Link to={props.To} ><button className='mt-2 fs-ss-16 fw-ss-400 btn-service-layout'>Read More</button></Link> 
    </div>
    </div>
    </>
  );
}

export default ServiceLayout;
