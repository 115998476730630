
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import UpperHeader from '../components/layout/UpperHeader';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BelowFooter from '../components/layout/BelowFooter';
import CommonBanner from '../components/common/CommonBanner';
import BannerImg from '../assets/images/Banners/PrivacyPolicyBanner.webp'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
    <Helmet>
      <title>
      Privacy Policy | Bright Kids Pediatrics Clinic
      </title>
    </Helmet>
        <UpperHeader/>
    <Header/>
    <CommonBanner
    ClsName='container-fluid bg-common bg-services d-block d-md-none'
    Title1="Privacy"
    Title2="Policy"
    Bg={BannerImg}
    />
    <section className="container-fluid px-3 px-md-5 py-4 py-md-5">
          <div className="col-12">
          <div className='fs-ss-32 font-ss-pri fw-ss-700 text-black py-2'>
          Privacy Statement
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          We are committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Statement of Privacy applies to our Practice’s Web site and governs data collection and usage. By using this website, you consent to the data practices described in this statement.</div>

          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Collection of your Personal Information
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          This practice collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. This Practice also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.</div>

          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Use of Your Personal Information
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          This practice reserves the right to change the terms, conditions, and notices under which this practice website is offered, including but not limited to the charges associated with the use of this practice website.</div>

          
          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Links to Third Party Sites
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          This Practice collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. This Practice also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.There is also information about your computer hardware and software that is automatically collected by this website. This information can include: your IP address, browser type, domain names, access times and referring Web site addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of this Web site. <div className='py-1' />
          
          Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through public message boards, this information may be collected and used by others.This Practice encourages you to review the privacy statements of Web sites you choose to link to from the website so that you can understand how those Web sites collect, use and share your information. This Practice is not responsible for the privacy statements or other content on any other Web sites.
          </div>
          
          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Use of Cookies
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          The Web site uses “cookies” to help this Practice personalize your online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
          </div>
          </div>

          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Security of Your Personal Information
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          This Practice secures your personal information from unauthorized access, use or disclosure. This Practice secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.
          </div>

          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Changes To This Statement
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          This Practice will occasionally update this Statement of Privacy to reflect company and customer feedback. We encourage you to periodically review this Statement to be informed of how this Practice is protecting your information.
          </div>

          <div className='fs-ss-26 font-ss-pri fw-ss-700 text-black py-2'>
          Contact Information
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          Please contact us by phone at <a className='text-decoration-none' target='_blank' href="tel:1234567890"> 123-456-7890. </a>
          </div>
          </section>
          <Footer/>
    <BelowFooter/>
    </>
  )
}
export default PrivacyPolicy;
