
import React from 'react';
import CommonButton from './CommonButton';

const BelowBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 pt-md-5 pt-4">
      <div className="row justify-content-evenly gy-4 align-items-center">
        <div className="d-none d-lg-block col-11 col-sm-4 col-md-5 text-center">
          <img className='w-85' src={props.Img1} alt={props.Alt} />
        </div>
        <div className="col-12 col-lg-6 ">
          <div className='fs-ss-22 text-ss-primary font-ss-pri fw-ss-400'>
          {props.SubTitle}
          </div>
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-700 py-2'>
            {props.Title}
          </div>
          <div className='fs-ss-16 fw-ss-400 lh-30'>
          {props.Para1_1}
          {props.br}
          {props.Para1_2}
          </div>
            </div>
            <div className="d-block d-lg-none col-11 col-lg-5 text-center">
          <img className='w-85' src={props.Img1} alt={props.Alt} />
        </div>
      </div>
    </section>
    </>


  );
}

export default BelowBanner;
