
import React from 'react';
import { StarFill } from 'react-bootstrap-icons';
import TestimonialsProfile from '../../assets/images/Imp/TestimonialsProfile.png'

const Testimonials = () => {
  return (
    <>
    <section id='website-responsive' className="container-fluid px-md-5 px-3 py-md-5 py-4 mt-4 mt-md-5" style={{backgroundColor:"#fff4d4"}}>
    <div className='fs-ss-22 text-ss-primary font-ss-pri fw-ss-600 text-center'>
          Patient Reviews
          </div>
          <div className='fs-ss-35 text-black font-ss-pri fw-ss-800 pt-2 pb-5 text-center'>
          Treating Every Patient <br className='d-none d-md-block' /> Like Family
          </div>
    <div className='row gx-4'>
                <div className='col-12 col-lg-4'>
                    <div className='border px-3 py-4 card-stretch bg-white rounded-1'>
                        <img className='rounded-circle' src={TestimonialsProfile} alt="Testimonials" style={{width:"50px"}} />
                        <a href='' target="blank" rel='noreferrer' className='text-raleway fs-ss-18 fw-700 text-dark text-decoration-none'>
                            <span className='text-raleway fs-ss-18 fw-700 ps-3'>
                            Ashli
                            </span>
                        </a>
                        
                        <br/>
                        <div className='pt-3 pb-2'>
                            <StarFill className='fs-ss-16 col-star me-1'/>
                            <StarFill className='fs-ss-16 col-star me-1'/>
                            <StarFill className='fs-ss-16 col-star me-1'/>
                            <StarFill className='fs-ss-16 col-star me-1'/>
                            <StarFill className='fs-ss-16 col-star me-1'/>
                        </div>
                        <div className=''>
                            <p className='fs-ss-14 my-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4 mt-md-0 mt-4'>
                    <div className='border px-3 py-4 card-stretch bg-white rounded-1'>
                        <img className='rounded-circle' src={TestimonialsProfile} alt="Testimonials" style={{width:"50px"}} />
                        <a href='' target="blank" rel='noreferrer' className='text-raleway fs-ss-18 fw-700 text-dark text-decoration-none'>
                            <span className='text-raleway fs-ss-18 fw-700 ps-3'>
                            Ashli
                            </span>
                        </a>
                        <br/>
                        <div className='pt-3 pb-2'>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                        </div>
                        <div className=''>
                            <p className='fs-ss-14 my-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4 d-md-block d-none'>
                    <div className='border px-3 py-4 card-stretch bg-white rounded-1'>
                        <img className='rounded-circle' src={TestimonialsProfile} alt="Testimonials" style={{width:"50px"}} />
                        <a href='' target="blank" rel='noreferrer' className='text-poppins fs-ss-18 fw-700 text-dark text-decoration-none'>
                            <span className='text-raleway fs-ss-18 fw-700 ps-3'>
                            Ashli
                            </span>
                        </a>
                        <br/>
                        <div className='pt-3 pb-2'>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                            <StarFill className='fs-ss-18 col-star me-1'/>
                        </div>
                        <div className=''>
                            <p className='fs-ss-14 my-0'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi ducimus corrupti odit dicta laudantium ut, quos harum cum provident veritatis, inventore, quod eius dolore assumenda voluptates earum ex laborum. Repudiandae delectus officia, officiis, architecto libero quae iste nostrum eum quaerat quia et error numquam aliquid repellat voluptates saepe accusantium ipsa sequi veritatis totam at recusandae
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' text-center pt-4 pt-md-5'>
            <a className="text-decoration-none" href='' target='_blank'>
          <button className="btn btn-ss-white-black shadow rounded-3 border-0  fs-ss-17 px-4 py-2 fw-ss-500">
          View All Reviews
          </button>
          </a>
            </div>
    </section>
    </>


  );
}

export default Testimonials;
