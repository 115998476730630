
import React from 'react';
import {Link} from 'react-router-dom'
import CommonButton from '../common/CommonButton';
import { useLocation } from "react-router-dom";
import LogoHeader from '../../assets/images/LogoHeader.webp';
import PDF1 from '../../assets/documents/NEW-PATIENT-PAPERWORK.pdf';
import PDF2 from '../../assets/documents/MEDICAL-RELEASE.pdf';


const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <div id='website-responsive'  className='container-fluid px-md-5 px-lg-3 px-xl-5 py-2 py-md-0'>
<nav className="navbar navbar-expand-lg">
  <Link className="navbar-brand" to="/"> 
  <img className="logo" alt="logo " id="logo" src={LogoHeader} /> </Link>
    <button className="navbar-toggler bg-ss-primary border-0 rounded-0 text-white px-2 pb-0 pt-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse ps-2 ps-lg-0 " id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto pt-2 mt-1">
        <li className="nav-item">
          <Link className={splitLocation[1] === "" ? "text-ss-primary nav-link px-xl-3 " : "nav-link px-xl-3  text-ss-primary-hover "} aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item ">
          <Link  className={splitLocation[1] === "about-us" ? "text-ss-primary nav-link px-xl-3 " : "nav-link px-xl-3  text-ss-primary-hover "} aria-current="page" to="/about-us">About Us</Link>
        </li>
        <li className="nav-item right-menu1">
          <Link className={splitLocation[1] === "services" ? "text-ss-primary nav-link px-xl-3  dropdown-toggle " : "nav-link px-xl-3  dropdown-toggle text-ss-primary-hover "} to="/services" role="button">
            Services
          </Link>
          {/* <ul className="dropdown-menu hover1 bg-white border border-light border-1 shadow-sm">
          <li> <Link className={splitLocation[1] === "newborn-care" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/newborn-care" > Newborn Care  </Link></li>
            <li><Link className={splitLocation[1] === "well-child-visits" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/well-child-visits" > Well Child Visits </Link></li>
            <li><Link className={splitLocation[1] === "sick-visits" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/sick-visits" > Sick Visits </Link></li>
            <li><Link className={splitLocation[1] === "lab-tests" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/lab-tests" > Lab Tests </Link></li>
            <li><Link className={splitLocation[1] === "mental-health" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/mental-health" > Mental Health </Link></li>
            <li><Link className={splitLocation[1] === "vaccines" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/vaccines" > Vaccines </Link></li>
            <li><Link className={splitLocation[1] === "covid-19" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/covid-19" > Covid-19 </Link></li>
            <li><Link className={splitLocation[1] === "primary-care" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/primary-care" > Primary Care </Link></li>
          </ul> */}

          <ul className="dropdown-menu hover1 border border-light border-1 shadow-sm">
          <li> <Link className={splitLocation[1] === "newborn-care" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/newborn-care" > Service 1  </Link></li>
            <li><Link className={splitLocation[1] === "well-child-visits" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/well-child-visits" > Service 2 </Link></li>
            <li><Link className={splitLocation[1] === "sick-visits" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/sick-visits" > Service 3 </Link></li>
            <li><Link className={splitLocation[1] === "lab-tests" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} to="/lab-tests" > Service 4 </Link></li>
          </ul>
        </li>
        <li className="nav-item ">
          <Link  className={splitLocation[1] === "providers" ? "text-ss-primary nav-link px-xl-3 " : "nav-link px-xl-3  text-ss-primary-hover "} aria-current="page" to="/providers">Providers</Link>
        </li>
        <li className="nav-item right-menu1">
          <div className="nav-link px-xl-3  dropdown-toggle text-ss-primary-hover ">
            Patient Forms
          </div>
          <ul className="dropdown-menu hover1 bg-white border border-light border-1 shadow-sm">
          <li> <a className={splitLocation[1] === "newborn-care" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} href={PDF1} target='_blank' > New Patient Paperwork </a></li>
            <li><a className={splitLocation[1] === "newborn-care" ? "text-ss-primary dropdown-item" : "dropdown-item text-ss-primary-hover "} href={PDF2} target='_blank' > Medical Release Form </a></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className={splitLocation[1] === "contact-us" ? "text-ss-primary nav-link px-xl-3 " : "nav-link px-xl-3  text-ss-primary-hover "} aria-current="page" to="/contact-us"> Contact Us </Link>
        </li>
        <li className="mx-lg-4 border-white border-end">
        </li>
        <li className="nav-item py-1 d-none d-lg-block ">
            <Link className='text-decoration-none' to="/book-an-appointment" rel="noreferrer" > 
            <CommonButton
            ClsName='btn btn-ss-primary-secondary rounded-3 border-0 px-4 py-2 fw-ss-500 d-block mb-3'
            Title="Book Online"
            />
            </Link>
        </li>
      </ul>
    </div>
</nav>
</div>
    </>
  );
}

export default Header;
