
import React from 'react';
import {Routes, Route} from 'react-router-dom';

import HomePage from '../src/pages/HomePage';

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap";
import '../src/assets/css/style.css';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPage from './pages/ServicesPage';
import ProvidersPage from './pages/ProvidersPage';
import ContactUsPage from './pages/ContactUsPage';

// Services Pages
import NewBornCare from './pages/ServicesPages/NewBornCare';
import WellChildVisits from './pages/ServicesPages/WellChildVisits';
import SickVisits from './pages/ServicesPages/SickVisits';
import MentaHealth from './pages/ServicesPages/MentaHealth';
import LabTests from './pages/ServicesPages/LabTests';
import Vaccines from './pages/ServicesPages/Vaccines';
import Covid19 from './pages/ServicesPages/Covid19';
import PrimaryCare from './pages/ServicesPages/PrimaryCare';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandConditions from './pages/TermsandConditions';
import BookanAppointment from './pages/BookanAppointment';

const App = () => {
  return (
    <>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/about-us" element={<AboutUsPage/>} />
          <Route path="/services" element={<ServicesPage/>} />
          <Route path="/providers" element={<ProvidersPage/>} />
          <Route path="/contact-us" element={<ContactUsPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-and-conditions" element={<TermsandConditions/>} />
          <Route path="/book-an-appointment" element={<BookanAppointment/>} />

          {/* Services Pages */}
          <Route path="/newborn-care" element={<NewBornCare/>} />
          <Route path="/well-child-visits" element={<WellChildVisits/>} />
          <Route path="/sick-visits" element={<SickVisits/>} />
          <Route path="/mental-health" element={<MentaHealth/>} />
          <Route path="/lab-tests" element={<LabTests/>} />
          <Route path="/vaccines" element={<Vaccines/>} />
          <Route path="/covid-19" element={<Covid19/>} />
          <Route path="/primary-care" element={<PrimaryCare/>} />
        </Routes>
    </>
  );
}

export default App;
