import React from "react"
import {Instagram, Facebook, TelephoneFill,EnvelopeFill,GeoAltFill, Linkedin, Youtube} from 'react-bootstrap-icons';
import InquiryForm from '../contactus/InquiryForm'

const ContactSection = (props) =>{
    return (
    <>
    <section id="website-responsive" className="container-fluid pt-4 ">
        <div className="text-center">
            <div className="fs-ss-35 fw-800 text-ss-primary">
            Contact Us
            </div>
            <div className="fs-ss-28 fw-700">
            Bright Kids Pediatrics Clinic
            </div>
        </div>
    </section>
    <section id='website-responsive' className='container-fluid pe-md-5'>
                <div className='py-md-0 py-3'>
                <div className="py-md-5">
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='text-ss-secondary' size={50} />
                                <div className='font-poppins fs-ss-22 text-ss-primary mt-3 mb-md-3'>
                                    <div className='fw-700' >Call Us</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="text-decoration-none text-black fs-ss-17 fw-600" target="_blank" href="tel:1234567890">Tel: 123-456-7890 </a></div>
                                    <div className='text-black fs-ss-17 fw-600'>Fax: 123-456-7890 </div>
                            </div>
                            
                        </div>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <EnvelopeFill className='text-ss-secondary' size={50} />
                                <div className='font-poppins fs-ss-22 text-ss-primary mt-3 mb-md-3'>
                                    <div className='fw-700' >Email Us</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><span className="text-decoration-none text-black fs-ss-17 fw-600"  target="_blank" href="">samplewebsite@gmail.com</span></div>
                            </div>
                            
                        </div>

                        <div className='col-12 col-xl-3 mb-3'>
                            <div className='my-2'>
                                <GeoAltFill className='text-ss-secondary' size={50} />
                                <div className='font-poppins fs-ss-22 text-ss-primary mt-3 mb-md-3'>
                                    <div className='fw-700' >Visit Us</div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                                    <div className=''><a className="text-decoration-none text-black fs-ss-17 fw-600"  target="_blank" href="https://www.google.com/maps/place/Main+Street+Pediatrics/@33.5846019,-86.2898629,15z/data=!4m5!3m4!1s0x0:0x6acabddbe7c5e1c8!8m2!3d33.5846019!4d-86.2898629?coh=164777&entry=tt&shorturl=1">  Washington DC, USA </a></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>
                <InquiryForm/>
            <section className="container-fluid pt-md-4">
                <div className="row font-pri justify-content-center align-items-center gy-5 bg-light pb-md-4">             
                <div className="col-11 col-xl-5 pe-md-5 text-center">
                    <img className="w-75" src={props.Mobile} alt="" />
                </div>
                    <div className="pb-4 col-12 col-xl-5 ps-xl-5 ms-xl-5 border-start border-2">
                    <div className="w-100 bg-pri pb-4">
                    <h3 className="fs-ss-30 font-poppins text-ss-primary fw-ss-700">
                    Clinic Hours
        </h3>
		<table className="text-black border-white table-bordered table-sm mt-3 w-100">
			<tbody>
				<tr>
					<td className="fw-400 py-2 ps-2" >Monday</td>
					<td className=" fs-ss-17 ps-2" >8:30 AM – 5:00 PM</td>
				</tr>
				<tr>
					<td className="fw-400 py-2 ps-2" >Tuesday</td>
					<td className=" fs-ss-17 ps-2" >8:30 AM – 5:00 PM</td>
				</tr>
				<tr>
					<td className="fw-400 py-2 ps-2" >Wednesday</td>
					<td className=" fs-ss-17 ps-2" >8:30 AM – 5:00 PM</td>
				</tr>
                <tr>
					<td className="fw-400 py-2 ps-2" >Thursday</td>
					<td className=" fs-ss-17 ps-2" >8:30 AM – 5:00 PM</td>
				</tr>
                <tr>
					<td className="fw-400 py-2 ps-2" >Friday</td>
					<td className=" fs-ss-17 ps-2" >8:30 AM – 5:00 PM</td>
				</tr>
                <tr>
					<td className="fw-400 py-2 ps-2" >Saturday</td>
					<td className=" fs-ss-17 ps-2" >1:00 PM – 4:00 PM</td>
				</tr>
                <tr>
					<td className="fw-400 py-2 ps-2" >Sunday</td>
					<td className=" fs-ss-17 ps-2" >1:00 PM – 4:00 PM</td>
				</tr>
			</tbody>
		</table>
        </div>
        <h3 className="mt-4 text-ss-primary fs-ss-30 fw-ss-700 font-poppins">
            Follow Us
            </h3>
            <div className="mt-1" >
            <a className="pe-3  text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.instagram.com/" >
            <Instagram className=""/></a>
            
            <a className="pe-3 text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.facebook.com/">
            <Facebook className=""/></a>

            <a className="pe-3 text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.linkedin.com/">
            <Linkedin className=""/></a>

            <a className="pe-3 text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.youtube.com/">
            <Youtube className=""/></a>
            </div>
        </div>
                </div>
            </section>

           <div className="container-fluid row justify-content-center py-md-5 py-3 gx-0 px-4">
            <div className="col-12">
            <iframe className="w-100"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99370.29950629387!2d-77.01457599999999!3d38.8937545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%2C%20DC%2C%20USA!5e0!3m2!1sen!2s!4v1717127539069!5m2!1sen!2s" width="800" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
            </div>
    </>
    )
}

export default ContactSection;