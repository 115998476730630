
import React from 'react';
import {Link} from 'react-router-dom'
import {Instagram, Facebook, TelephoneFill, GeoAltFill, ChevronRight, Linkedin, Youtube } from 'react-bootstrap-icons';

import LogoFooter from '../../assets/images/LogoFooter.webp';


const Footer = () => {
  return (
    <>
    <section id='website-responsive' className="container-fluid bg-footer">
    <div className='px-md-5 py-md-5 py-4'>
      <div className="row gy-5 justify-content-evenly">
        <div className="col-11 col-lg-3 text-center align-self-center">
          <div className=''>
            <img className="logo-footer bg-white p-3" src={LogoFooter} alt="Logo" />
          </div>
          <div className="py-md-3 py-2"/>
          <a href='tel:1234567890' target='_blank' className='text-decoration-none'>
            <button className="btn fs-ss-18 rounded-4 shadow py-2 px-4" style={{backgroundColor:"#fad35b"}}>
           <TelephoneFill className='text-black'/> <span className='ps-2'/> <span className='text-black fw-ss-600'> 123-456-7890 </span>
            </button>
            </a>
            <div className="py-md-3 py-2"/>
            <div>
            <a className="pe-3  text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.instagram.com/" >
            <Instagram className=""/></a>
            
            <a className="pe-3 text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.facebook.com/">
            <Facebook className=""/></a>

            <a className="pe-3 text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.linkedin.com/">
            <Linkedin className=""/></a>

            <a className="pe-3 text-decoration-none text-black " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.youtube.com/">
            <Youtube className=""/></a>
            </div>
        </div>
        <div className="col-11 col-lg-4 pe-xl-4">
        <div className="fs-ss-26 fw-ss-600 text-black">
        Clinic Hours
        </div>
		<table className="text-black table-bordered table-sm mt-3 w-100">
			<tbody className='border-white' >
				<tr className="lh-30">
					<td className=" fs-ss-17 ps-2" >Monday</td>
					<td className=" fs-ss-17 ps-2" >8:30AM - 5:00PM</td>
				</tr>
				<tr className='bg-light lh-30'>
					<td className=" fs-ss-17 ps-2" >Tuesday</td>
					<td className=" fs-ss-17 ps-2" >8:30AM - 5:00PM</td>
				</tr>
				<tr className="lh-30">
					<td className=" fs-ss-17 ps-2" >Wednesday</td>
					<td className=" fs-ss-17 ps-2" >8:30AM - 5:00PM</td>
				</tr>
                <tr className='bg-light lh-30'>
					<td className=" fs-ss-17 ps-2" >Thursday</td>
					<td className=" fs-ss-17 ps-2" >8:30AM - 5:00PM</td>
				</tr>
                <tr className="lh-30">
					<td className=" fs-ss-17 ps-2" >Friday</td>
					<td className=" fs-ss-17 ps-2" >8:30AM - 5:00PM</td>
				</tr>
                <tr className='bg-light lh-30'>
					<td className=" fs-ss-17 ps-2" >Saturday</td>
					<td className=" fs-ss-17 ps-2" >1:00PM - 4:00PM</td>
				</tr>
                <tr className="lh-30">
					<td className=" fs-ss-17 ps-2" >Sunday</td>
					<td className=" fs-ss-17 ps-2" >1:00PM - 4:00PM</td>
				</tr>
			</tbody>
		</table>
        </div>
        <div className="col-11 col-lg-4">
          <div>
          <div className="fs-ss-26 fw-ss-600 text-black pb-2 ">
          Contact Details
        </div>
        <div className="pb-1"><a className="lh-30 text-black text-decoration-none fs-ss-17"  target="_blank" rel="noreferrer" href="tel:1234567890" >
           <TelephoneFill className="me-2"/> 123-456-7890 </a></div>
        <div className="pb-1"><a className="lh-30 text-black text-decoration-none fs-ss-17"  target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/PkNu74KqRbPHRTfu9">
        <GeoAltFill className="me-2"/> Washington DC, USA </a> </div>
          </div>
          <div className='pt-3 pt-md-4'/>
          <div>
          <div className="fs-ss-26 fw-ss-600 text-black pb-2 ">
          Quick Links
        </div>
        <div className="pb-1"> <ChevronRight className='fs-ss-17'/> <Link className="text-black fs-ss-17 text-decoration-none lh-30"  to="/about-us"> About Us</Link> </div>
        <div className="pb-1">  <ChevronRight className='fs-ss-17'/> <Link className="text-black fs-ss-17 text-decoration-none lh-30"  to="/services">Services</Link> </div>
        <div className="pb-1">  <ChevronRight className='fs-ss-17'/> <Link className="text-black fs-ss-17 text-decoration-none lh-30"  to="/providers">Providers</Link> </div>
        <div className="pb-1">  <ChevronRight className='fs-ss-17'/> <Link className="text-black fs-ss-17 text-decoration-none lh-30"  to="/book-an-appointment">Book Appointment</Link> </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    </>
  );
}

export default Footer;
